import erickOffice from "./Assets/galleryImages/erick-office.jpg";
import erickPhoneAirport from "./Assets/galleryImages/erick-phone-airport.jpg";
import kingWalk from './Assets/galleryImages/Walk-like-a-king.jpg';
import bikeHead from './Assets/galleryImages/erick-suit-bicycle.jpg';
import tableSitting from './Assets/galleryImages/sitting-on-table.jpg';
import suitwalking from './Assets/galleryImages/erick-suit-walking.jpg';
import snow1 from './Assets/galleryImages/erickSnow.jpg';
import snow2 from './Assets/galleryImages/erickSnow2.jpg';
import snow3 from './Assets/galleryImages/erickSnow3.jpg';

// Marley Campaign
import marley1 from './Assets/galleryImages/marley1.jpg';
import marley2 from './Assets/galleryImages/marley2.jpg';
import marley3 from './Assets/galleryImages/marley3.jpg';
import marley4 from './Assets/galleryImages/marley4.jpg';
import marley5 from './Assets/galleryImages/marley5.jpg';
import marley6 from './Assets/galleryImages/marley6.jpg';
import marley7 from './Assets/galleryImages/marley7.jpg';
import marley8 from './Assets/galleryImages/marley8.jpg';
import marley9 from './Assets/galleryImages/marley9.jpg';

// Nike Jordan Campaign
import nike1 from './Assets/galleryImages/nike1.jpg';
import nike2 from './Assets/galleryImages/nike2.jpg';
import nike3 from './Assets/galleryImages/nike3.jpg';
import nike4 from './Assets/galleryImages/nike4.jpg';
import nike5 from './Assets/galleryImages/nike5.jpg';

// Canam Campaign
import canam1 from './Assets/galleryImages/canam1.jpg';
import canam2 from './Assets/galleryImages/canam2.jpg';
import canam3 from './Assets/galleryImages/canam3.jpg';
import canam4 from './Assets/galleryImages/canam4.jpg';
import canam5 from './Assets/galleryImages/canam5.jpg';
import canam6 from './Assets/galleryImages/canam6.jpg';
import canam7 from './Assets/galleryImages/canam7.jpg';
import canam8 from './Assets/galleryImages/canam8.jpg';
import canam9 from './Assets/galleryImages/canam9.jpg';

// Seadoo Campaign
import seadoo1 from './Assets/galleryImages/erick-seadoo1.jpg';
import seadoo2 from './Assets/galleryImages/erick-seadoo2.jpg';
import seadoo3 from './Assets/galleryImages/erick-seadoo3.jpg';
import seadoo4 from './Assets/galleryImages/erick-seadoo4.jpg';
import seadoo5 from './Assets/galleryImages/erick-seadoo5.jpg';


export const Images = [
    {
        picture: erickOffice, alt: "Office Modeling"
    },
    {
        picture: erickPhoneAirport, alt: "Office in airport"
    },
    {
        picture: kingWalk, alt: "Walking like a king"
    },
    {
        picture: suitwalking, alt: "Walking in a suit"
    },
    {
        picture: tableSitting, alt: "Sitting on a table"
    },
    {
        picture: bikeHead, alt: "Explorers club"
    },
    {
        picture: snow1, alt: "Snow day sitting and smiling"
    },
    {
        picture: snow2, alt: "Snow day side view"
    },
    {
        picture: snow3, alt: "Snow day standing and smiling"
    },
];


export const marleyImages = [
    {
        picture: marley1, alt: "Marley Campaign"
    },
    {
        picture: marley2, alt: "Marley Campaign"
    },
    {
        picture: marley3, alt: "Marley Campaign"
    },
    {
        picture: marley4, alt: "Marley Campaign"
    },
    {
        picture: marley5, alt: "Marley Campaign"
    },
    {
        picture: marley6, alt: "Marley Campaign"
    },
    {
        picture: marley7, alt: "Marley Campaign"
    },
    {
        picture: marley8, alt: "Marley Campaign"
    },
    {
        picture: marley9, alt: "Marley Campaign"
    },
];

export const nikeJordanImages = [
    {
        picture: nike1, alt: "Nike Jordan Campaign"
    },
    {
        picture: nike2, alt: "Nike Jordan Campaign"
    },
    {
        picture: nike3, alt: "Nike Jordan Campaign"
    },
    {
        picture: nike4, alt: "Nike Jordan Campaign"
    },
    {
        picture: nike5, alt: "Nike Jordan Campaign"
    },
];

export const canamImages = [
    {
        picture: canam1, alt: "Canam Campaign"
    },
    {
        picture: canam2, alt: "Canam Campaign"
    },
    {
        picture: canam3, alt: "Canam Campaign"
    },
    {
        picture: canam4, alt: "Canam Campaign"
    },
    {
        picture: canam5, alt: "Canam Campaign"
    },
    {
        picture: canam6, alt: "Canam Campaign"
    },
    {
        picture: canam7, alt: "Canam Campaign"
    },
    {
        picture: canam8, alt: "Canam Campaign"
    },
    {
        picture: canam9, alt: "Canam Campaign"
    },
];

export const seadooImages = [
    {
        picture: seadoo1, alt: "Seadoo Campaign"
    },
    {
        picture: seadoo2, alt: "Seadoo Campaign"
    },
    {
        picture: seadoo3, alt: "Seadoo Campaign"
    },
    {
        picture: seadoo4, alt: "Seadoo Campaign"
    },
    {
        picture: seadoo5, alt: "Seadoo Campaign"
    },
];